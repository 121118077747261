<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <h6 class="breadcrumb-text">
          Use Cases / Summarize a Case / {{ this.$route.params.id }}
        </h6>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <PageLoader
          v-if="loading"
          :loading="loading"
          :extra_msg="loading_message"
          :completedPercentage="duration"
        />
        <!-- Add v-else in div below -->

        <div class="page-container" v-else>
          <div class="main-content">
            <section class="files-section">
              <div class="section-header">Contract Due Diligence</div>
              <button class="view-files-btn">View Uploaded Files</button>
              <div class="security-notice">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ee5e1527866344adfafc18849c89c62dd76f2b9c7ded99add1ae660ece66da12?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                  alt="Security icon"
                  class="security-icon"
                />
                <p class="security-text">
                  Your files will be securely stored in NeXa, accessible only to
                  you and your workspace admins, and you can delete them at any
                  time.
                </p>
              </div>
            </section>

            <section class="features-section">
              <div class="features-header">
                <div class="header-content">
                  <h1 class="title">Ask NeXa test</h1>
                  <p class="subtitle">
                    Here's what NeXa can do with your uploaded files:
                  </p>
                </div>
                <div class="search-container">
                  <form class="search-form" role="search">
                    <label for="searchInput" class="visually-hidden"
                      >Search features</label
                    >
                    <div class="search-wrapper">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5df906573a5faa9ed54311d4653d575cafb33930e9f4ab04dac2653585abc1a0?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                        alt="Search icon"
                        class="search-icon"
                      />
                      <input
                        type="search"
                        id="searchInput"
                        class="search-input"
                        placeholder="Search"
                        aria-label="Search features"
                      />
                    </div>
                  </form>
                </div>
              </div>

              <section class="use-cases" aria-label="Legal Use Cases">
                <article
                  v-for="(useCase, index) in useCases"
                  :key="index"
                  class="use-case-card"
                >
                  <div class="use-case-content">
                    <h3 class="use-case-title">{{ useCase.title }}</h3>
                    <p class="use-case-description">
                      {{ useCase.description }}
                    </p>
                  </div>
                </article>
              </section>
            </section>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    ></sidebar>
  </div>
</template>
<script>
import countryImages from "../../../assets/country/index";
import PageLoader from "../../PageLoader.vue";
import sidebar from "../sidebar.vue";
import usecase from "@/store/usecase.js";
import { ExportDataPDF } from "@/store/utils";
// import VueMarked from "vue-marked";
export default {
  components: {
    sidebar,
    // VueMarked,
    PageLoader,
    //   ChatBox,
  },
  data() {
    return {
      useCases: [
        {
          title: "Regulatory Compliance Review",
          description:
            "Analyze contracts against relevant regulatory frameworks (e.g., GDPR, HIPAA), flagging areas of non-compliance and suggesting corrective actions.",
        },
        {
          title: "Risk Analysis",
          description:
            "Identify and assess potential legal risks or ambiguities in contract terms, aligned with jurisdiction-specific regulations and industry standards.",
        },
        {
          title: "Due Diligence Review",
          description:
            "Conduct a comprehensive due diligence review, detecting missing clauses, ambiguities, or inconsistencies with legal norms and standards.",
        },
        {
          title: "Clause Comparison",
          description:
            "Compare key clauses across multiple contract versions, highlighting changes, potential legal implications, and impacts on obligations or liabilities.",
        },
        {
          title: "Draft Revisions",
          description:
            "Automatically generate legally sound revisions to contract clauses, ensuring compliance with client-specific requirements and legal frameworks.",
        },
        {
          title: "Concise Summary",
          description:
            "Generate a clear, concise summary of the contract's key elements, simplifying complex legal language for client understanding and approval.",
        },
      ],
      loading_message: "Please wait a moment while we process it.",
      duration: 0,
      loading: true,
      interval: null,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCopied: false,
      isDropdown: false,
      showSummary: false,
      countryImages,
      files: [],
      values: {},
      documentBrief: {},
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    toggleSummary() {
      this.showSummary = !this.showSummary;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: "ContractDiligence" });
    },
    copy() {
      const contentElement = document.querySelector("article").innerText;

      navigator.clipboard
        .writeText(contentElement)
        .then(() => {
          this.$toast.success("Copied as plain text successfully!");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying to clipboard");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },

    exportToPDF() {
      try {
        this.$nextTick(() => {
          const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Build An Argument: ${this.$route.params.id} - Summarized Document : ${this.$route.params.docId}</h1><br><hr>`;
          const tempDiv = document.createElement("div");

          // Style the temporary div
          tempDiv.style.width = "65%";
          tempDiv.style.maxWidth = "800px";
          tempDiv.style.margin = "20px auto";
          tempDiv.style.padding = "20px";
          tempDiv.style.border = "1px solid #ccc";
          tempDiv.style.borderRadius = "10px";
          tempDiv.style.backgroundColor = "#f9f9f9";
          tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
          tempDiv.style.fontFamily = "Arial, sans-serif";

          const contentElement = document.querySelector("article").innerHTML;
          if (!contentElement) {
            this.$toast.error("Content not found");
            return;
          }

          tempDiv.innerHTML = followUpQuestion + contentElement;

          document.body.appendChild(tempDiv);

          ExportDataPDF(
            tempDiv.innerHTML,
            false,
            `Build An Argument: ${this.$route.params.id} - Summarized Document : ${this.$route.params.docId}`
          );

          this.$toast.success("Successfully downloaded PDF");

          document.body.removeChild(tempDiv);
        });
      } catch (err) {
        this.$toast.error("Error exporting to PDF");
        console.error(err);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    usecase.GetResult(this.$route.params.id ?? "").then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.duration = DATA.completedPerCentage;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }

      if (DATA.completedPerCentage < 100) {
        if (DATA.completedPerCentage >= 0) {
          this.duration = DATA.completedPerCentage;
          this.files = DATA.questionDocuments;
        }

        this.interval = setInterval(() => {
          usecase.GetResult(this.$route.params.id).then((res) => {
            if (res.data.data.completedPerCentage >= 100) {
              this.duration = res.data.data.completedPerCentage;
              clearInterval(this.interval);
              location.reload();
            } else if (res.data.data.completedPerCentage > 0) {
              this.duration = res.data.data.completedPerCentage;
            }
          });
        }, 10000);
      } else {
        console.log(DATA);
      }
    });
  },
};
</script>

<style scoped>
.marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

.marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

.marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

.marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

.marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 800 !important;
  margin-bottom: 1.5rem !important;
}

.marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 700 !important;
  margin-bottom: 1.25rem !important;
}

.marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

.marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

.marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

.marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

.marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  font-family: Poppins, sans-serif;
  padding: 148px 80px 64px;
}

.main-content {
  width: 100%;
  max-width: 1156px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.files-section {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section-header {
  font-size: 16px;
  color: var(--Primary-Blue, #0e4485);
  font-weight: 500;
  line-height: 28px;
}

.view-files-btn {
  align-self: flex-start;
  border-radius: 4px;
  color: var(--Status-Blue, #1890ff);
  font-size: 14px;
  padding: 2px 8px;
  border: none;
  background: none;
  cursor: pointer;
}

.security-notice {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  line-height: 24px;
}

.security-icon {
  width: 18px;
  height: 18px;
}

.features-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.header-content {
  min-width: 240px;
  color: var(--Neutral-Black, #383a3e);
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.subtitle {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.search-form {
  min-width: 240px;
  width: 421px;
}

.search-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  padding: 8px 20px;
}

.search-icon {
  width: 24px;
  height: 24px;
}

.search-input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 11px;
}

.feature-card {
  flex: 1 1 240px;
  max-width: 454px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  padding: 24px;
}

.feature-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: var(--Neutral-Black, #383a3e);
  margin: 0 0 8px;
}

.feature-description {
  font-size: 14px;
  line-height: 24px;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin: 0;
}

@media (max-width: 991px) {
  .page-container {
    padding: 100px 20px 0;
  }

  .main-content,
  .files-section,
  .search-form,
  .feature-card {
    max-width: 100%;
  }

  .feature-card {
    padding: 20px;
  }
}

.use-cases {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: Poppins, sans-serif;
  justify-content: start;
  flex-wrap: wrap;
}

.use-case-card {
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  background-color: #fff;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  flex-grow: 1;
  width: 454px;
  padding: 24px;
}

.use-case-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.use-case-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.use-case-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

@media (max-width: 991px) {
  .use-case-card {
    max-width: 100%;
    padding: 0 20px;
  }

  .use-case-content {
    max-width: 100%;
  }

  .use-case-description {
    max-width: 100%;
  }
}
</style>
